import React from 'react';
import '../Common/common.css';

interface OrderChangeCounterProps {
  currentNumber: number;
  previousNumber: number;
  fromDeliveries: boolean;
  newText?: string;
}

const OrderChangeCounter: React.FC<OrderChangeCounterProps> = ({ currentNumber, previousNumber, fromDeliveries }) => {
  // // const [difference, setDifference] = useState<number | null>(null);

  // // Calculate the difference and set the animation effect
  // useEffect(() => {
  //   if (previousNumber !== undefined) {
  //     setDifference(currentNumber - previousNumber);
  //   }
  // }, [currentNumber, previousNumber]);

  // Determine the difference color
  // const differenceColor = difference! > 0 ? 'green' : difference! < 0 ? 'red' : 'green';

  return (
    <div className="slot-machine-container">
      <div className="slot-machine-card">
        <div className="number-display" style={!fromDeliveries ? { fontSize: '28px' } : {}}>
          <div className="number-container">
            {/* Key is set to currentNumber to trigger reanimation */}
            <div className="old-number" key={`old-${previousNumber}`}>
              {previousNumber}
            </div>
            <div className="new-number" key={`new-${currentNumber}`}>
              {currentNumber}
            </div>
          </div>
        </div>
        {/* {difference !== null && (
            <div className="difference" style={{ color: differenceColor }}>
              {difference > 0 ? '+' : ''}
              {difference}
            </div>
          )} */}
      </div>
    </div>
  );
};

export default OrderChangeCounter;
