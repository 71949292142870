import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { getPrecedingZero } from '../../Utilities/getPrecedingZero';
import { getOrderTimeZone } from '../../Utilities/getZonalTime';
import OrderChangeCounter from '../../Common/OrdersChangeCounter';

export const OrderStatus = {
  'Rejected by vendor': '#DC3545',
  'Confirmed': '#30a64a',
  'Unconfirmed': '#646464',
  'Cancelled': '#DC3545'
}

type SingleDayMeetingsProps = {
    singleDay: any;
    index: any;
    restaurant_ids_for_single_day_orders: any;
    getOrderLast24HoursTrend: any;
    navigateToOrderDetails: any;
    vendorTimeZone: any;
    restaurantOrdersOnSingleDay: any;
    restaurantName: any;
    BulkAccept?: any;
    bulkArray?: any;
    setAllMeetingsAccepted?: any
    unconfirmedMeets?: any
}

const SingleDayMeetings = ({
    singleDay,
    index,
    restaurant_ids_for_single_day_orders,
    getOrderLast24HoursTrend,
    navigateToOrderDetails,
    vendorTimeZone,
    restaurantOrdersOnSingleDay,
    restaurantName,
    BulkAccept,
    bulkArray,
  setAllMeetingsAccepted,
  unconfirmedMeets
}: SingleDayMeetingsProps) => {

  const [chkAll, setChkAll] = useState<boolean>(false)
  const [restaurantList, setRestaurantList] = useState(restaurantOrdersOnSingleDay)
  const [Ids, setIds] = useState<any>({})

  useEffect(() => {
    setRestaurantList(restaurantOrdersOnSingleDay)
  }, [restaurantOrdersOnSingleDay])


  const AcceptAllBtnStatus = restaurantList?.every((value: any) => value?.status === 'Confirmed' || value?.status === 'Cancelled');

  let bulkArrayOfMeetingId: any;
  bulkArrayOfMeetingId = []


  const checkerselect = (e: React.ChangeEvent<HTMLInputElement>, resName: any, resID: any, index?: any) => {
    const { name, checked } = e.target;
    let updatedSelectedMeetings = [...restaurantList];
    let selectAll = false;
    let selectAllStatus;
    if (name === 'select-all') {
      updatedSelectedMeetings = updatedSelectedMeetings?.map(restaurant => ({ ...restaurant, isChecked: checked }));
      if (checked) {
        setChkAll(true)
      } else {
        setChkAll(false)
      }
    } else if (updatedSelectedMeetings[index]) {
      selectAllStatus = updatedSelectedMeetings?.find(status => status?.isChecked === 'false');
      updatedSelectedMeetings[index].isChecked = checked;
      const meetingId = (updatedSelectedMeetings[index].status !== 'Cancelled' || updatedSelectedMeetings[index].status !== 'Confirmed') ? updatedSelectedMeetings[index].order_id : '00';
      if (checked) {
        bulkArrayOfMeetingId.push(meetingId)
        setIds({ ...Ids, [meetingId]: true })
      } else {
        setIds({...Ids, [meetingId]:false})
      }

    }
    if (!checked) {
      setChkAll(false)
    }

    const AcceptBtnStatus = updatedSelectedMeetings?.some(status => status?.isChecked === true);
    if (AcceptBtnStatus){
      BulkAccept(true)
    }else{
      BulkAccept(false)
    }

      setRestaurantList(updatedSelectedMeetings)
    selectAllStatus = updatedSelectedMeetings?.every(status => status?.isChecked === true);
    if (checked === true) {
      if (selectAllStatus) {
        selectAll = true
        setChkAll(selectAll)
      }
    }

    const selectedMeetings = updatedSelectedMeetings?.filter((restaurant: any) => ((restaurant?.status === "Cancelled" || restaurant?.status === "Confirmed") ? false : restaurant.isChecked === true))

    const selectedMeetingsIds = selectedMeetings?.map((restaurant: any) => restaurant?.order_id)

    bulkArray({'restaurant_address_id': resID, 'meeting_ids':selectedMeetingsIds})
  };
  // const shortAddress = restaurantList[0].restaurant_address?.split(',')
  // const streetAddress = shortAddress ? shortAddress[0] + ', ' + shortAddress[1] : ''
  // const restaurantNameAndAddr = restaurantList[0].restaurant_name + ',\t' + streetAddress
    return (
      <div style={{ marginBottom: 10 }} >
        <div className='d-flex align-items-center pb-2 pt-4'>
          <div className='d-flex align-items-center select-all-chkbox'>
            <input
              type='checkbox'
              name='select-all'
              id='select-all-toggler'
              checked={chkAll}
              disabled={AcceptAllBtnStatus}
              onChange={(e) => checkerselect(e, restaurantName, restaurantList[0].restaurant_address_id)}
              className='cursor-pointer'
            />
          </div>
          <div>
          </div>
          <label htmlFor="select-all-orders" className='mb-0 select-all-orders'>
            <i className='fas fa-store' style={{ color: '#157FFC' }} />
            <h5 className='pl-2 m-0 d-inline-block' style={{ color: '#157FFC' }}>{restaurantList[0].restaurant_address_format}</h5>
          </label>

        </div>
        <table className='table order-history-records table-hover border-0 table-data text-center'>
          <tbody>
            {restaurantList?.map((value: any, idx: any, array: any) => {
              const {
                pickup_at,
                status,
                order_id,
                cutoff_at,
                sd_cutoff_at,
                items_count,
                restaurant_address_id,
                new_items_in_last24_hours,
                same_day,
                time_zone
              } = value
              restaurant_ids_for_single_day_orders.push(restaurant_address_id);
              const last24HoursOrderTrend = getOrderLast24HoursTrend(new_items_in_last24_hours || 0);
              const isCutoffReached = dayjs(same_day ? sd_cutoff_at : cutoff_at).isBefore(new Date());

              return <tr className='bg-white tableList border-bottom cursor-default' key={order_id} >
                <td className='order-checkbox'>
                  {(status !== 'Confirmed' || status !== 'Cancelled') ? <input type="checkbox" name="single-order" id="single-order" checked={value.isChecked ? value.isChecked : false} disabled={(status === 'Confirmed' || status === 'Cancelled') ? true : false} onChange={(e) => checkerselect(e, restaurantName, restaurant_address_id, idx)} />
                    :
                    <input type="checkbox" name="single-order" id="single-order" checked={false} disabled={(status === 'Confirmed' || status === 'Cancelled') ? true : false} />
                  }
                </td>
                {same_day ? <td
                  data-tip
                  data-for={`order-list-${order_id}`}
                  className='border-0 text-nowrap text-left table-data-padding'
                >
                  <span style={{ fontSize: 18 }}>{order_id}-SD</span>
                  {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                    <ReactTooltip
                      type='dark'
                      place='top'
                      effect='solid'
                      className='react-tooltip'
                      id={`order-list-${order_id}`}
                    >
                      <span>Same Day Order</span>
                    </ReactTooltip>
                  }
                  <p style={{ fontSize: 16 }}>Order ID</p>
                </td>
                  :
                  <td className='border-0 text-nowrap text-left table-data-padding'>
                    <span style={{ fontSize: 18 }}>{order_id}</span>
                    <p style={{ fontSize: 16 }}>Order ID</p>
                  </td>
                }
                <td className='border-0 text-nowrap text-left'>
                  <span style={{ fontSize: 18 }}>
                    {/* {dayjs(pickup_at).format('hh:mm a')} */}
                    { `${getOrderTimeZone(pickup_at, 'h:mm a',time_zone)?.split(' ')?.[0]} ${getOrderTimeZone(pickup_at, 'h:mm a',time_zone)?.split(' ')?.[1]}`}
                  </span>
                  <p style={{ fontSize: 16 }}>Pickup</p>
                </td>
                <td className='border-0 text-nowrap text-left'>
                  {/* <span style={{ fontSize: 18 }}>{getPrecedingZero(items_count)}</span> */}

                  <OrderChangeCounter currentNumber={+getPrecedingZero(items_count)} previousNumber={+getPrecedingZero(0)} fromDeliveries={true} />

                  <p style={{ fontSize: 16 }}>Items
                    {last24HoursOrderTrend ?
                      <span style={{ color: last24HoursOrderTrend.color, paddingLeft: 1 }}>
                        (<i className={`fa-solid fa-arrow-trend-${last24HoursOrderTrend.className} mx-1`}></i>
                        <span className='mr-1' style={{ fontSize: 16 }}>{last24HoursOrderTrend.count}</span>)
                      </span> : null}
                  </p>
                </td>
                <td className='border-0 text-nowrap text-left'>
                  {same_day ? <>
                    <span
                      data-tip
                      className='text-capitalize'
                      data-for={`order-history-${order_id}-SD`}
                      style={{ fontSize: 18, color: isCutoffReached ? '#dc3545' : '#157ffc' }}
                    >
                      {isCutoffReached ? 'Reached' : dayjs(sd_cutoff_at).toNow(true)}
                    </span>
                    {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                      <ReactTooltip
                        type='dark'
                        place='bottom'
                        effect='solid'
                        className='react-tooltip'
                        id={`order-history-${order_id}-SD`}
                      >
                        <span>{getOrderTimeZone(sd_cutoff_at, 'EEE. MMM dd h:mma, zzzz', time_zone)}</span>
                      </ReactTooltip>
                    }
                  </>
                    :
                    <>
                      <span
                        data-tip
                        className='text-capitalize'
                        data-for={`order-history-${order_id}`}
                        style={{ fontSize: 18, color: isCutoffReached ? '#dc3545' : '#157ffc' }}
                      >
                        {isCutoffReached ? 'Reached' : dayjs(cutoff_at).toNow(true)}
                      </span>
                      {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                        <ReactTooltip
                          type='dark'
                          place='bottom'
                          effect='solid'
                          className='react-tooltip'
                          id={`order-history-${order_id}`}
                        >
                          <span>{getOrderTimeZone(cutoff_at, 'EEE. MMM dd h:mma, zzzz', time_zone)}</span>
                        </ReactTooltip>
                      }
                    </>
                  }
                  <p style={{ fontSize: 16 }}>Cutoff</p>
                </td>
                <td className='border-0 text-nowrap text-left' style={{ width: 165 }}>
                  <span
                    className='confirmed text-capitalize '
                    //@ts-ignore
                    style={{ backgroundColor: OrderStatus[status] }}
                  >
                    {status}
                  </span>
                </td>
                <td className='border-0 text-nowrap text-right pr-4' style={{ width: 150 }}>
                  <button
                    className='btn btn-sm btn-light'
                    onClick={() => navigateToOrderDetails(restaurant_address_id, order_id)}
                  >
                    View Details
                  </button>
                </td>
              </tr>
            }

            )}
          </tbody>
        </table>
      </div>
      );
}

export default SingleDayMeetings;